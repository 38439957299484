<template>
  <div class="homeInspectionRecords">
     <!-- 查询 -->
    <div class="filter-panel">
      <CSSelect style="width: 230px; margin-right: 0;">
          <el-date-picker
              style=" width: 210px;"
              v-model="queryData.startTime"
              type="datetime"
              format="yyyy-MM-dd hh:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              prefix-icon="el-icon-time"
              :editable="false"
          ></el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect style="width: 230px;">
          <el-date-picker
              style=" width: 210px;"
              v-model="queryData.endTime"
              type="datetime"
              format="yyyy-MM-dd hh:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              prefix-icon="el-icon-time"
              :editable="false"
          ></el-date-picker>
      </CSSelect>
        <input
                type="text"
                placeholder="搜索房间/租客/提交人/备注"
                class="cs-input"
                style="margin: 0; margin-bottom: 15px;width: 290px;height: 30px "
                v-model.trim="queryData.search"

        />
        <CSSelect style="margin: 0 30px 15px 20px;">
          <select style="width: 149px;padding:0 5px;"  v-model="queryData.type">
            <option value="" style="width: 123px;">全部验房类型</option>
            <option v-for="(name, type) in typeList" :key="type"
                  :value="type+1">
                {{name}}
            </option>
          </select>
        </CSSelect>
        <button
                type="button"
                class="btn btn-primary"
                @click="query()"
        >
          查询
        </button>

    </div>
     <div class="result-panel">
         <CSTable :thead-top="filterHeight">
             <template v-slot:thead>
                 <tr>
                     <th style="width:220px">验房时间</th>
                     <th>验房类型</th>
                     <th >房间</th>
                     <th >租客</th>
                     <th >提交人</th>
                     <th >验房详情</th>
                 </tr>
             </template>
             <template v-slot:tbody>
                 <tr v-for="(item) in checkRecordList" :key="item.id">
                     <td class="date-td">{{item.checkTime}}</td>
                     <td >{{typeList[item.type-1]}}</td>
                     <td >{{item.buildingName}}{{item.floorCode}}层{{item.roomName}}</td>
                     <td >{{item.lesseeName || '-'}}</td>
                     <td >{{item.submitUser}}</td>
                     <td >
                <span
                    class="allow-click"
                    @click="lookDetails(item.id)"
                >
                  查看
                </span>
                     </td>
                 </tr>
             </template>
         </CSTable>
        <Pagination/>
     </div>
  </div>
</template>
<script>
import {
  queryCheckRecord,
}
from "@/requestUrl"
import Pagination from "@/components/Pagination";
import CSSelect from "@/components/common/CSSelect";
import dayjs from "dayjs";
import CSTable from "@/components/common/CSTable";
export default {
  name:'homeInspectionRecords',
  components:{
      CSTable,
    CSSelect,
    Pagination,
  },
  data(){
    return {
        filterHeight: 0,
      queryData:{
        startTime: dayjs().subtract(7, 'day').format('YYYY-MM-DD')+' 00:00:00',
        endTime: dayjs().format('YYYY-MM-DD')+' 23:59:59',
        search:'',
        type:''
      },
      typeList:[
        '入驻验房',
        '退租验房',
        '其它验房'
      ],
      checkRecordList:[],
    }
  },
  mounted () {
    this.query()
    this.$vc.on(this.$route.path, "pagination","page_event",(currentPage)=>{
        this.query(currentPage)
    })
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      window.addEventListener('resize', () => {
          this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      })
  },
  created(){
    window.addEventListener("keydown",this.queryDown);
  },
  beforeDestroy(){
    window.removeEventListener("keydown",this.queryDown);
  },
  deactivated(){
     window.removeEventListener("keydown",this.queryDown);
  },
  methods:{
    queryDown(e){
      if(e.keyCode == 13){
        this.query();
      }
    },
    query (pageNo = 1, pageSize = 10) {
      this.$fly.post(queryCheckRecord, {
        pageNo,
        pageSize,
        ...this.queryData,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        if(pageNo === 1){
          this.$vc.emit(this.$route.path, 'pagination', 'init', {
            pageSize,
            total:res.data.total,
            currentPage: pageNo,
          })
        }
        this.checkRecordList=res.data.datas
      })

    },
    elDisabledDate(date, isStartDate) {
      if (isStartDate) {
          return dayjs(date).isBefore(dayjs(this.queryData.endTime).subtract(30, 'day'));
      }
      return dayjs(date).isAfter(dayjs(this.queryData.startTime).add(30, 'day'));
    },
    // 查看详情
    lookDetails (id) {
      this.$router.push({
				name:"checkRoomDetails",
				params:{
					id
				}
			});

    },
  }
}
</script>
<style  scoped lang="stylus">

</style>
